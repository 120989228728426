<template>
  <div></div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { ref, onBeforeMount, computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    onBeforeMount(() => {
      if (route.query.login_code && route.query.state) {
        store.dispatch("user/wxLogin", {
          code: route.query.login_code,
          state: route.query.state
        }).then(data => {
          if (data.status === 2) {
            router.push({ path: '/login', query: { ...data, jumpUrl: encodeURIComponent(route.query.jump_url) } });
          } else {
            router.push(decodeURIComponent(route.query.jump_url));
          }
        }).catch(message => {
          console.log(message);
        });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
</style>